body{
  background: #ecf1f9;

}

.main{
  width: 100%;
  padding: 20px;
  margin:2rem auto;
  background: #fff7;
  border-radius: 10px;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
}
p{margin-bottom: 0;}

.from-control{
  border-radius: 10px !important;
}


